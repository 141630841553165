
/* eslint-disable @intlify/vue-i18n/no-dynamic-keys */
import { defineComponent, computed, onMounted } from 'vue';
import { middleEllipsis } from '@/utils';
import {
  account,
  modalConnectOpen,
  disconnect,
  reconnectWallet,
} from '@/hooks';

export default defineComponent({
  setup() {
    const accountId = computed(() => {
      return account.value;
    });
    const openModalConnect = () => {
      modalConnectOpen.value = true;
    };
    onMounted(async () => {
      await reconnectWallet();
    });
    return {
      accountId,
      openModalConnect,
      disconnect,
      middleEllipsis,
    };
  },
});
