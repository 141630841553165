
  <el-dialog
    title="Connect Wallet"
    v-model="modalConnectOpen"
    width="30%"
    center
  >
    <span></span>
    <div v-for="wallet in listWallets" :key="wallet">
      <button
        @click="onClickWallet(wallet)"
        :disabled="isConnecting"
        class="w-full focus:outline-none border border-gray-500 mb-3 py-1"
      >
        {{ $t(`WALLET.${wallet.toUpperCase()}`) }}
      </button>
    </div>
  </el-dialog>
