import { render } from "./DefaultHeader.vue?vue&type=template&id=4410fb49&scoped=true"
import script from "./DefaultHeader.vue?vue&type=script&lang=ts"
export * from "./DefaultHeader.vue?vue&type=script&lang=ts"

import "./DefaultHeader.vue?vue&type=style&index=0&id=4410fb49&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4410fb49"

export default script

/* vue-cli-plugin-import-components */
import ModalConnectWallet from "@/components/common/ModalConnectWallet.vue";
script.components = Object.assign({}, { ModalConnectWallet }, script.components);if (script.options) { script.options.components = Object.assign({}, { ModalConnectWallet }, script.options.components);}