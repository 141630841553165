
import { defineComponent, computed } from 'vue';
import { SUPPORTED_WALLETS } from '@/utils';
import { modalConnectOpen, connect, isConnecting } from '@/hooks';

export default defineComponent({
  setup() {
    const listWallets = computed(() => {
      return [...SUPPORTED_WALLETS];
    });
    const onClickWallet = async wallet => {
      await connect(wallet);
      modalConnectOpen.value = false;
    };
    return {
      listWallets,
      modalConnectOpen,
      isConnecting,
      onClickWallet,
    };
  },
});
