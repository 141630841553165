
  <div>
    <header class="default-header">
      <nav class="topbar">
        <div class="topbar__content">
          <h1 class="text-3xl font-bold text-white">Demo</h1>
          <div>
            <span v-if="accountId" class="text-white text-base mr-3">
              Account: {{ middleEllipsis(accountId) }}
            </span>
            <button
              v-if="accountId"
              @click="disconnect"
              class="
                text-white
                border border-solid border-white
                px-6
                py-1
                focus:outline-none
              "
            >
              Disconnect
            </button>
            <button
              v-else
              @click="openModalConnect"
              class="
                text-white
                border border-solid border-white
                px-6
                py-1
                focus:outline-none
              "
            >
              Connect Wallet
            </button>
          </div>
        </div>
      </nav>
    </header>
    <ModalConnectWallet />
  </div>
